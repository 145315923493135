var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"no-body":"","header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_c('router-link',{attrs:{"to":{ name: 'list-machine' }}},[_vm._v(_vm._s(_vm.$t("MENU.ITEM.ASSETS.MACHINES")))]),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LABELS.FILES"))+" - ")]),_c('b',[_vm._v(_vm._s(_vm.machine ? _vm.machine.name : null))])],1)]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-light-success font-weight-bolder",on:{"click":_vm.handleClickAddNew}},[_c('i',{staticClass:"flaticon2-plus"}),_vm._v(" "+_vm._s(_vm.$t("LABELS.ADD_NEW"))+" ")])])]},proxy:true}])},[(!_vm.isPageLoading)?_c('v-card',[_c('v-data-table',{staticClass:"px-6 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoadingMachine,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD.MM.YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"warning","x-small":""},on:{"click":function($event){return _vm.handleClickDownload(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.DOWNLOAD")))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"error","x-small":""},on:{"click":function($event){return _vm.handleClickRemove(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("BUTTON.DELETE")))])])],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}],null,true)})],1):_vm._e(),(_vm.isPageLoading)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isPageLoading,"type":"table"}}):_vm._e()],1),_c('MachineFileAdd',{ref:"MachineFileAdd",attrs:{"id":_vm.machine ? _vm.machine.id : null,"type":_vm.machine ? _vm.machine.expenseType : null},on:{"add":_vm.fetchMachine}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }