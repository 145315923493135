<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="white--text primary">
        <span class="assignment-item-title text-h5">
          Добави нов файл
        </span>
      </v-card-title>
      <v-card-text>
        <v-container class="assignment-form-container">
          <b-form-group
            id="item-name-group"
            v-bind:label="$t('FORM_LABELS.NAME')"
            label-for="item-name-input"
          >
            <FormInput
              type="text"
              :i18n="{
                label: 'FORM_LABELS.NAME',
                placeholder: $t('FORM_LABELS.NAME')
              }"
              v-model="form.name"
              :validateState="{}"
            ></FormInput>
          </b-form-group>
          <b-form-group
            class="mt-5"
            id="item-file-group"
            v-bind:label="$t('FORM_LABELS.FILE')"
            label-for="item-file-input"
          >
            <b-input-group>
              <b-form-file
                v-model="form.file"
                :placeholder="$t('FORM_LABELS.FILE')"
              ></b-form-file>
            </b-input-group>
          </b-form-group>
          <div
            v-show="_.size(errors) > 0"
            role="alert"
            class="alert alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ Array.isArray(error) ? error[0] : error }}
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="dialog = false">
          Затвори
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleSave">
          Запази
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SAVE_FILE } from "@/core/services/store/shared_file.module";
import FormInput from "@/view/content/forms/components/FormInput.vue";
export default {
  props: {
    id: Number,
    type: String
  },
  components: {
    FormInput
  },
  data() {
    return {
      dialog: false,
      form: {
        name: null,
        file: null
      },
      errors: {},
      formKey: 0
    };
  },
  methods: {
    show() {
      this.dialog = true;
      this.resetForm();
    },
    closeModal() {
      this.dialog = false;
    },
    handleSave() {
      this.errors = {};
      this.$store
        .dispatch(SAVE_FILE, {
          name: this.form.name,
          file: this.form.file,
          foreign_id: this.id,
          foreign_type: this.type
        })
        .then(data => {
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          this.closeModal();
          this.$emit("add");
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});

          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    resetForm() {
      this.form = {
        name: null,
        file: null
      };
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-item-title
  flex: 1
  word-break: normal
</style>
