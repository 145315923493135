<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            <router-link :to="{ name: 'list-machine' }">{{
              $t("MENU.ITEM.ASSETS.MACHINES")
            }}</router-link>
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.FILES") }} - </small
            ><b>{{ machine ? machine.name : null }}</b>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-light-success font-weight-bolder"
            @click="handleClickAddNew"
          >
            <i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}
          </button>
        </div>
      </template>
      <v-card v-if="!isPageLoading">
        <v-data-table
          class="px-6 elevation-1"
          :headers="headers"
          :items="items"
          :loading="isLoadingMachine"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    fab
                    color="warning"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClickDownload(item.id)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.DOWNLOAD") }}</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    fab
                    color="error"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClickRemove(item.id)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("BUTTON.DELETE") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
    <MachineFileAdd
      ref="MachineFileAdd"
      :id="machine ? machine.id : null"
      :type="machine ? machine.expenseType : null"
      @add="fetchMachine"
    />
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

//Provider Module
import { FETCH_MACHINE } from "@/modules/machines/store/machine.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import MachineFileAdd from "../components/MachineFileAdd.vue";

import {
  GENERATE_DOWNLOAD_FILE_URL,
  DELETE_FILE
} from "@/core/services/store/shared_file.module";

export default {
  mixins: [permissionMixin],
  components: {
    MachineFileAdd
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      columns: {},
      headers: [
        {
          text: this.$i18n.t("FORM_LABELS.NAME"),
          value: "name"
        },
        { text: this.$i18n.t("FORM_LABELS.REAL_NAME"), value: "realName" },
        {
          text: this.$i18n.t("FORM_LABELS.FILE_SIZE"),
          value: "fileSize"
        },
        {
          text: this.$i18n.t("FORM_LABELS.CREATED_AT"),
          value: "createdAt"
        },

        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ],
      machine: null
    };
  },
  computed: {
    ...mapGetters(["isLoadingMachine"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery
      };
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASSETS.MACHINES"),
        route: { name: "list-machine" }
      }
    ]);
    this.fetchMachine();
  },
  methods: {
    fetchMachine() {
      const id = this.$route.params.id;
      this.$store.dispatch(FETCH_MACHINE, id).then(({ data }) => {
        this.machine = data;
        this.items = data.files;
        this.isPageLoading = false;
      });
    },
    handleClickAddNew() {
      this.$refs.MachineFileAdd.show();
    },
    handleClickDownload(id) {
      this.$store.dispatch(GENERATE_DOWNLOAD_FILE_URL, id).catch(response => {
        this.errors = response.data.errors;
        this.$notify({
          group: "notify",
          type: "error",
          title: this.$t("ALERTS.ERROR"),
          text: response.data.message
        });
      });
    },
    handleClickRemove(id) {
      const message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(DELETE_FILE, id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.items.splice(
                this.items.findIndex(i => i.id === id),
                1
              );
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response.message
              });
            });
        })
        .catch(() => {});
    }
  }
};
</script>
